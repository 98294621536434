import { createSlice } from "@reduxjs/toolkit"



const slice = createSlice({
    name: 'modal',
    initialState: {
        title: '',
        message: '',
        show: false,
        showWindowBox: false,
        windowBoxData: {
            src: '',
            name: '',
            size: ''
        },
        invalidFields: [],
        lockNav: false
    },
    reducers: {
        closeModal: (state)=>{
            state.title = '';
            state.message = '';
            state.show = false;
            state.invalidFields = [];
        },
        openModal: (state, action)=>{
            state.title = action.payload.title;
            state.message = action.payload.message;
            state.show = true;
            state.invalidFields = action.payload.invalidFields;
        },
        openWindowBox: (state, action)=>{
            state.showWindowBox = true;
            state.windowBoxData.src = action.payload.src;
            state.windowBoxData.name = action.payload.name;
            state.windowBoxData.size = action.payload.size;
        },
        closeWindowBox: (state) => {
            state.showWindowBox = false;
            state.windowBoxData.src = '';
            state.windowBoxData.name = '';
            state.windowBoxData.size = '';
            
        },
        lockNav: (state)=>{
            state.lockNav = true;
        },
        unlockNav: (state)=>{
            state.lockNav = false;
        }
    }

});

export default slice.reducer;

export const {closeModal, openModal, openWindowBox, closeWindowBox, lockNav, unlockNav} = slice.actions;