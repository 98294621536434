import { configureStore, combineReducers } from "@reduxjs/toolkit";
import formReducer from './form';
import modalReducer from './modal';

const reducer = combineReducers({
    form: formReducer,
    modal: modalReducer
})

const store = configureStore({
    reducer
})

export default store;