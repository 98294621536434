import { createSlice } from "@reduxjs/toolkit";
import {v4 as uuid} from 'uuid';

const slice = createSlice({
    name: 'form',
    initialState: {
        isValid: false,
        imagesLoading: false,
        formLoading: false,
        totalImageSize: 0,
        fields: [
            {
                name: 'firstName',
                label: 'First Name',
                value: '',
                isValid: false,
                validation: 'plainText',
                touched: false,
                type: 'text',
                required: true
            },
            {
                name: 'lastName',
                label: 'Last Name',
                value: '',
                isValid: false,
                validation: 'plainText',
                touched: false,
                type: 'text',
                required: true
            },
            {
                name: 'email',
                label: 'Email',
                value: '',
                isValid: false,
                validation: 'email',
                touched: false,
                type: 'email',
                required: true
            },
            {
                name: 'phone',
                label: 'Phone',
                value: '',
                isValid: false,
                validation: 'plainText',
                touched: false,
                type: 'tel',
                required: true
            },
            {
                name: 'street',
                label: 'Street',
                value: '',
                isValid: false,
                validation: 'plainText',
                touched: false,
                type: 'text',
                required: true
            },
            {
                name: 'zip',
                label: 'Zip',
                value: '',
                isValid: false,
                validation: 'plainText',
                touched: false,
                type: 'text',
                required: true
            },
            {
                name: 'city',
                label: 'City',
                value: '',
                isValid: false,
                validation: 'plainText',
                touched: false,
                type: 'text',
                required: true
            },
            {
                name: 'state',
                label: 'State',
                value: 'PA',
                isValid: true,
                validation: 'plainText',
                touched: false,
                type: 'text',
                required: true
            },
            {
                name: 'description',
                label: 'Project Description',
                value: '',
                isValid: false,
                validation: 'plainText',
                touched: false,
                type: 'textarea',
                required: true
            },
            {
                name: 'budget',
                label: 'Budget',
                value: '',
                type: 'number',
                isValid: true,
                validation: 'none',
                required: false
            },
            {
                name: 'tasks',
                label: 'Tasks',
                required: false,
                validation: 'none',
                isValid: true,
                checkboxes: [
                    {
                        name: 'ceramicTile',
                        label: 'Ceramic Tile',
                        checked: false,
                    },
                    {
                        name: 'showerAndTub',
                        label: 'Shower/Tub',
                        checked: false,
                    },
                    {
                        name: 'plumbing',
                        label: 'Plumbing',
                        checked: false,
                    },
                    {
                        name: 'flooring',
                        label: 'Flooring',
                        checked: false,
                    },
                    {
                        name: 'painting',
                        label: 'Painting',
                        checked: false,
                    },
                    {
                        name: 'demolition',
                        label: 'Demolition',
                        checked: false,
                    },
                    {
                        name: 'electrical',
                        label: 'Electrical',
                        checked: false,
                    },
                    {
                        name: 'cabinetry',
                        label: 'Cabinetry',
                        checked: false,
                    },
                    {
                        name: 'insulation',
                        label: 'Insulation',
                        checked: false,
                    },
                    {
                        name: 'framing',
                        label: 'Framing',
                        checked: false,
                    },
                    {
                        name: 'trim',
                        label: 'Trim',
                        checked: false,
                    },
                    {
                        name: 'doors',
                        label: 'Doors',
                        checked: false,
                    },
                    {
                        name: 'railings',
                        label: 'Railings',
                        checked: false,
                    }
                ]
            },
            {
                name: 'images',
                label: 'Images',
                value: '',
                imageData: [],
                type: 'file',
                isValid: true,
                validation: 'none',
                required: false
            },

        ]
    },

    reducers: {
        updateTextField: (state, action) => {
            const updatedFieldIndex = state.fields.findIndex((field) => field.name === action.payload.id);
            let valueString = action.payload.value.toString();
            state.fields[updatedFieldIndex].value = valueString;
            
            switch(state.fields[updatedFieldIndex].validation){
                case 'plainText':
                    state.fields[updatedFieldIndex].isValid = action.payload.value.trim() !== '';
                break;
                case 'email':
                    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
                    state.fields[updatedFieldIndex].isValid = emailRegex.test(action.payload.value.trim())
                break;
                default:
                    state.fields[updatedFieldIndex].isValid = true;
                break;
            }
            
        },

        touchTextField: (state, action) => {
            const updatedFieldIndex = state.fields.findIndex((field) => field.name === action.payload.id);
            state.fields[updatedFieldIndex].touched = action.payload.touched
        },

        tickCheckbox: (state, action) => {
            const checkboxes = state.fields.find((field) => field.name === 'tasks').checkboxes
            const updatedCheckboxIndex = checkboxes.findIndex((checkbox) => checkbox.name === action.payload.id)
            checkboxes[updatedCheckboxIndex].checked = !checkboxes[updatedCheckboxIndex].checked;
        },

        addImages: (state, action) => {
            const imageField = state.fields.find((field) => field.name === 'images');
            imageField.imageData = [...imageField.imageData, ...action.payload.imageData];
            let batchSize = 0;
            action.payload.imageData.forEach((image) =>{
                batchSize += image.size;
            })
            state.totalImageSize += batchSize;
        },
        deleteImage: (state, action) => {
            const imageField = state.fields.find((field) => field.name === 'images');
            const imageToDelete = imageField.imageData.find((image)=> image.imageId === action.payload.imageId);
            state.totalImageSize -= imageToDelete.size;
            imageField.imageData = imageField.imageData.filter((image) => image.imageId !== action.payload.imageId);
            
        },

        setImagesLoading: (state, action) => {
            state.imagesLoading = action.payload.loading
        },

        setFormLoading: (state, action) => {
            state.formLoading = action.payload.loading
        },

        resetForm: (state) => {
            state.isValid = false;
            state.imagesLoading = false;
            state.formLoading = false;
            state.totalImageSize = 0;
            state.fields = [
                {
                    name: 'firstName',
                    label: 'First Name',
                    value: '',
                    isValid: false,
                    validation: 'plainText',
                    touched: false,
                    type: 'text',
                    required: true
                },
                {
                    name: 'lastName',
                    label: 'Last Name',
                    value: '',
                    isValid: false,
                    validation: 'plainText',
                    touched: false,
                    type: 'text',
                    required: true
                },
                {
                    name: 'email',
                    label: 'Email',
                    value: '',
                    isValid: false,
                    validation: 'email',
                    touched: false,
                    type: 'email',
                    required: true
                },
                {
                    name: 'phone',
                    label: 'Phone',
                    value: '',
                    isValid: false,
                    validation: 'phone',
                    touched: false,
                    type: 'tel',
                    required: true
                },
                {
                    name: 'street',
                    label: 'Street',
                    value: '',
                    isValid: false,
                    validation: 'plainText',
                    touched: false,
                    type: 'text',
                    required: true
                },
                {
                    name: 'zip',
                    label: 'Zip',
                    value: '',
                    isValid: false,
                    validation: 'plainText',
                    touched: false,
                    type: 'text',
                    required: true
                },
                {
                    name: 'city',
                    label: 'City',
                    value: '',
                    isValid: false,
                    validation: 'plainText',
                    touched: false,
                    type: 'text',
                    required: true
                },
                {
                    name: 'state',
                    label: 'State',
                    value: 'PA',
                    isValid: true,
                    validation: 'plainText',
                    touched: false,
                    type: 'text',
                    required: true
                },
                {
                    name: 'description',
                    label: 'Project Description',
                    value: '',
                    isValid: false,
                    validation: 'plainText',
                    touched: false,
                    type: 'textarea',
                    required: true
                },
                {
                    name: 'budget',
                    label: 'Budget',
                    value: '',
                    type: 'number',
                    required: false,
                    validation: 'none',
                    isValid: true
                },
                {
                    name: 'tasks',
                    label: 'Tasks',
                    required: false,
                    isValid: true,
                    validation: 'none',
                    checkboxes: [
                        {
                            name: 'ceramicTile',
                            label: 'Ceramic Tile',
                            checked: false,
                        },
                        {
                            name: 'showerAndTub',
                            label: 'Shower/Tub',
                            checked: false,
                        },
                        {
                            name: 'plumbing',
                            label: 'Plumbing',
                            checked: false,
                        },
                        {
                            name: 'flooring',
                            label: 'Flooring',
                            checked: false,
                        },
                        {
                            name: 'painting',
                            label: 'Painting',
                            checked: false,
                        },
                        {
                            name: 'demolition',
                            label: 'Demolition',
                            checked: false,
                        },
                        {
                            name: 'electrical',
                            label: 'Electrical',
                            checked: false,
                        },
                        {
                            name: 'cabinetry',
                            label: 'Cabinetry',
                            checked: false,
                        },
                        {
                            name: 'insulation',
                            label: 'Insulation',
                            checked: false,
                        },
                        {
                            name: 'framing',
                            label: 'Framing',
                            checked: false,
                        },
                        {
                            name: 'trim',
                            label: 'Trim',
                            checked: false,
                        },
                        {
                            name: 'doors',
                            label: 'Doors',
                            checked: false,
                        },
                        {
                            name: 'railings',
                            label: 'Railings',
                            checked: false,
                        }
                    ]
                },
                {
                    name: 'images',
                    label: 'Images',
                    value: '',
                    files: null,
                    imageData: [],
                    type: 'file',
                    validation: 'none',
                    required: false,
                    isValid: true
                },

            ]
        }
    }


});

export default slice.reducer;

export const {
    updateImageField,
    setImagesLoading,
    setFormLoading,
    updateTextField,
    touchTextField,
    resetForm,
    tickCheckbox,
    deleteImage,
    addImages
} = slice.actions


export const processImageUploads = (payload) => async (dispatch) => {
    dispatch(setImagesLoading({ loading: true }));
    const loadImage = (image, batchNumber) => {
        const imageId = uuid();
        let reader = new FileReader();
        let size = '';
        if(image.size < 1024) {
            size = `${image.size} bytes`
        } else if (image.size < 1048576){
            size = `${(image.size /1024).toFixed(0)} KB`
        } else if (image.size < 1073741824){
            size = `${(image.size / 1048576 ).toFixed(2)} MB`
        } else {
            size = `${(image.size / 1073741824 ).toFixed(2)} GB`
        }
        return new Promise((resolve) => {
            reader.onload = () => {
                resolve({ 
                    name: image.name, 
                    data: reader.result, 
                    size: image.size, 
                    UISize: size, 
                    imageId: imageId, 
                    batchNumber: batchNumber 
                })
            }
            reader.readAsDataURL(image)
        })
    }

    const uploadImages = () => {
        let imageFilePromises = [];
        let unsupportedFiles = [];
        for (let i = 0; i < payload.formImages.length; i++) {
            let batchNumber = i + 1;
            if(payload.formImages[i].type === "image/jpeg" || payload.formImages[i].type === "image/png"){
            imageFilePromises.push(loadImage(payload.formImages[i], batchNumber))
            }
            else{
                unsupportedFiles.push(payload.formImages[i].name)
            }
        }
        if(unsupportedFiles.length > 0){
            alert(`This form only accepts png and jpeg images. The following files were not added:\n\n${unsupportedFiles.join("\n")}`);
        }
        return Promise.all(imageFilePromises)
    }

    const finishedUploads = await uploadImages()

    dispatch(addImages({ imageData: finishedUploads }));
    dispatch(setImagesLoading({ loading: false }));
};